body {
    font-family: "Roboto";
    margin: 0;
    padding: 0;
    font-size: 12pt;
}

.PageLoader {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: center;
    width: 100%
}

.LoginContianer {
    height: 100vh;
    width: 100vw
}

.PageContent {
    min-height: 100px;
    width: 100%
}

.profilepic {
    width: 100%;
    border-radius: 50%;
    border: 1px #333 solid;
}

.navlink,
.navlink:visited {
    color: #555;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0.75rem 0.75rem;
}

.navlink:hover {
    color: #000;
    background-color: rgb(241, 241, 241);
}

.empnav,
.empnav:visited {
    color: #555;
    text-decoration: none;
}

.empnav:hover {
    color: #000;
    background-color: rgb(241, 241, 241);
}

.navlink2,
.navlink2:visited {
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0.75rem 0.75rem;
}

.navlink2:hover {
    color: #2f3193;
    background-color: rgb(241, 241, 241);
}

.navlink3,
.navlink3:visited {
    color: #000;
    text-decoration: none;
    padding: 0.75rem 0.75rem;
}

.navlink3:hover {
    color: #2f3193;
}

.siteLoader {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: center;
    width: 100%
}

.img-fluid {
    width: 100%;
}

.file {
    display: flex;
    flex-direction: row;
    padding: 2px;
    background-color: #eceff1;
    border-radius: 5px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
}

.fileItem {
    height: 75px;
}

.filedata {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.fileInfo {
    font-size: 9pt;
    padding: 2px 4px;
    color: #00012c;
    margin-top: 5px;
    color: #00012c;
}

.filename {
    font-size: 10pt;
    font-weight: bold;
    padding: 2px 4px;
    color: #850000;
    margin-top: 3px;
    word-break: break-all;
}

.fileActions {
    align-self: flex-end;
    margin-bottom: 3px;
    text-align: right;
    padding-right: 4px;
}

.w-100 {
    width: 100%;
}

.vh-50 {
    height: 50vh;
}

.ListerData {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 50vh;
    justify-content: center;
    width: 100%
}

.submitLoader {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 30vh;
    justify-content: center;
    width: 30vw;
    position: fixed;
    border-radius: 10px;
    top: 35vh;
    left: 35vw;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3000;
}

.sitContent {
    height: 80vh;
    margin: auto;
    overflow-y: scroll
}

.sitContent::-webkit-scrollbar {
    display: none
}

.flexy {
    align-items: center;
    display: flex;
    height: 74vh;
    justify-content: center
}

.d-none {
    display: none
}

.LoginImage {
    width: 10vw
}

.footercontianer {
    text-align: end;
    margin-right: 10px;
}

.appfooter {
    text-align: center;
    margin-right: 10px;
}

.bolder,
.bolder:hover,
.bolder:visited {
    font-weight: bold;
    color: #850000;
    text-decoration: none;
}

.bolder2 {
    font-weight: bold;
    color: #1b5e20;
}

.text-soc {
    color: #2f3193;
}

.pdfLink {
    display: inline-block;
    padding: 0.5rem 0;
    text-decoration: none;
    background-color: #850000;
    color: #fff;
    border-radius: 3px;
    width: 100%;
    text-align: center;
}

.pdfLink:hover {
    background-color: #9c1a1a;
}

.dateview {
    color: #850000;
}
.appbut{
    width: 150px;
}
.bg-soc {
    background-color: #2f3193
}

.text-small {
    font-size: small
}

.text-xsmall {
    font-size: x-small
}

.newfeat {
    padding: 0.5rem;
    font-size: 12pt;
}

.MuiDataGrid-cellContent {
    font-size: 10pt;
}

.MuiAlert-action>button {
    display: none;
}

.ql-container {
    height: 150px;
}

.border-bottom {
    border-bottom: 1px #2f3193 solid;
}

@media only screen and (max-width: 600px) {
    .text-small {
        font-size: x-small
    }
}

.breabcrumblink {
    color: dimgray;
    text-decoration: none
}

.breabcrumblink:hover {
    text-decoration: underline
}

.MuiDataGrid-row:nth-child(2n) {
    background-color: #b0c4de
}

.EngHI {
    text-align: left;
    direction: ltr;
}

.AraHI {
    text-align: right;
    direction: rtl;
}

.videoPlayer {
    width: 100%;
}
.MuiStepLabel-labelContainer span {
    font-size: x-small;
}
.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.HiLinks {
    display: block;
    text-align: center;
}

.HiLinks {
    display: block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: #fff;
    background-color: #2f3193;
    border-color: #2f3193;
    text-decoration: none;
    margin: 0.375rem 0;
}

.HiLinks:hover {
    text-decoration: none;
    color: #fff;
    background-color: #00012c;
    border-color: #00012c;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.lds-grid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #2f3193;
    animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
    top: 8px;
    left: 8px;
    animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
}

@keyframes lds-grid {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #2f3193;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

.lds-circle {
    display: inline-block;
    transform: translateZ(1px);
}

.lds-circle>div {
    display: inline-block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    background: #2f3193;
    animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes lds-circle {

    0%,
    100% {
        animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }

    0% {
        transform: rotateY(0deg);
    }

    50% {
        transform: rotateY(1800deg);
        animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }

    100% {
        transform: rotateY(3600deg);
    }
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #2f3193;
    border-color: #2f3193 transparent #2f3193 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #2f3193;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
}

@keyframes lds-facebook {
    0% {
        top: 8px;
        height: 64px;
    }

    50%,
    100% {
        top: 24px;
        height: 32px;
    }
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #2f3193;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #2f3193 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.lds-default {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-default div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #2f3193;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
}

.lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
}

.lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
}

.lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
}

.lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
}

.lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
}

.lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
}

.lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
}

.lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
}

.lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
}

.lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
}

.lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
}

.lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
}

@keyframes lds-default {

    0%,
    20%,
    80%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }
}